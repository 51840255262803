<template>
  <div>
    <div class="card-body">
      <div class="row">
        <div class="form-group col-md-8">
          <label for="descripcion">Empresa</label>
          <v-select
            :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
            v-model="empresas"
            multiple
            label="razon_social"
            :options="listasForms.empresas"
            placeholder="Empresa"
          ></v-select>
        </div>
        <div class="form-group col-md-4">
          <div class="btn-group float-right">
            <button
              type="button"
              class="btn btn-success mt-4"
              @click="seleccionarEmpresas()"
            >
              Seleccionar todas
            </button>
            <button
              type="button"
              class="btn btn-danger mt-4"
              @click="deseleccionarEmpresas()"
            >
              Quitar todas
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-4">
          <div class="btn-group">
            <button class="btn bg-primary" @click="asignarEmpresas()">
              <i class="fas fa-plus"></i><br />Asignar empresas
            </button>
            <button
              class="btn bg-navy"
              v-if="empresasLista.length > 0"
              @click="enviarCorreo()"
            >
              <i class="fas fa-envelope"></i><br />Enviar Correos
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <table
          v-if="empresasLista.length > 0"
          class="table table-bordered table-striped table-hover table-sm"
        >
          <thead class="thead-dark">
            <tr>
              <th>Empresas Asignadas</th>
              <th style="width: 15px">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="empresa in empresasLista" :key="empresa.id">
              <td>{{ empresa.razon_social }}</td>
              <td>
                <div class="btn-group float-right">
                  <button
                    type="button"
                    class="btn btn-sm bg-navy"
                    @click="enviarCorreo(empresa)"
                  >
                    <i class="fas fa-envelope"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
export default {
  name: "PlaneacionCorreo",
  components: { vSelect },
  data() {
    return {
      planeacion_id: null,
      empresas: {},
      empresasLista: [],
      listasForms: {
        empresas: [],
      },
      idLineaNegocio: 2,
    };
  },
  methods: {
    init() {
      axios
        .get("/api/hidrocarburos/planeaciones/" + this.planeacion_id)
        .then((response) => {
          this.getEmpresasAsignadas(response.data.empresas);
        });
    },

    getEmpresasAsignadas(empresas) {
      let array = [];
      empresas.forEach((empresa) => {
        array.push({
          id: empresa.id,
          razon_social: empresa.razon_social,
        });
      });
      this.empresas = array;
      this.empresasLista = array;
    },

    getEmpresas() {
      axios
        .get("/api/admin/empresas/EmpresaHidrocarburos", {
          params: {
            id_linea_negocio: this.idLineaNegocio,
          },
        })
        .then((response) => {
          this.listasForms.empresas = response.data;
        });
    },

    seleccionarEmpresas() {
      this.empresas = this.listasForms.empresas;
    },

    deseleccionarEmpresas() {
      this.empresas = null;
    },

    enviarCorreo(empresa = null) {
      if (this.empresas !== null || empresa) {
        this.$swal({
          title: "Esta seguro de enviar el correo?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Enviar!",
        }).then((result) => {
          // <--
          if (result.value) {
            this.$parent.cargando = true;
            let empresas = [];
            if (empresa) {
              empresas.push(empresa);
            } else {
              empresas = this.empresas;
            }

            axios
              .post("/api/hidrocarburos/planeaciones/enviarCorreo", {
                empresas,
                planeacion_id: this.$parent.form.id,
              })
              .then((response) => {
                this.$parent.cargando = false;
                this.$swal({
                  icon: "success",
                  title: "Correo enviado satisfactoriamente",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              })
              .catch(function (error) {
                this.$parent.cargando = false;
                this.$swal({
                  icon: "error",
                  title: "Error, en el envio de correo:" + error,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              });
          }
        });
      } else {
        this.$swal({
          icon: "error",
          title: "Para enviar el correo debe seleccionar al menos una empresa",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    asignarEmpresas() {
      if (this.empresas.length > 0) {
        let me = this;
        this.$parent.cargando = true;

        axios
          .post("/api/hidrocarburos/planeaciones/asignarEmpresas", {
            empresas: this.empresas,
            planeacion_id: this.planeacion_id,
          })
          .then((response) => {
            this.init();
            this.$parent.cargando = false;

            this.$swal({
              icon: "success",
              title:
                "Se asiganron las empresas a la planeación satisfactoriamente",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch(function (error) {
            me.$swal({
              icon: "error",
              title: "Error, en el envío de correo: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            me.$parent.cargando = true;
          });
      } else {
        this.$swal({
          icon: "error",
          title: "Debe seleccionar al menos una empresa",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },
  },
  mounted() {
    this.getEmpresas();
  },
};
</script>
